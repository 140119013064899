<div class="row mx-0 pb-0" *ngIf="(catalogFacade.getFetchCategoriesState() | async)?.loading">
  {{'loading' | translate}}
</div>

<div class="row mx-0 pb-0 position-relative menu-category"
  *ngIf="!(catalogFacade.getFetchCategoriesState() | async)?.loading">
  <ul class="w-100 px-0 mt-0">
    <li *ngIf="categoryParent" class="row mx-0 py-2 px-1 category-parent">
      <span class="col-2 d-block text-center px-0 my-auto cursor-pointer"
        (click)="onReturn(categoryParent.categoryParentId)">
        <span class="k-arrow-left f-size-28px mx-auto"></span>
        <span class="f-size-10px d-block  ">{{'back' | translate}}</span>
      </span>
      <span class="col-10 my-auto d-flex text-center pr-2">
        <!--{{categoryParent.categoryParentId}}-->
        <img [src]="categoryParent.fullUrlPictureLarge" [alt]="categoryParent.designation"
          [title]="categoryParent.designation" class="my-auto mr-2" >
        <span class="d-block category-parent-designation">{{categoryParent.designation}}</span>
      </span>
    </li>


    <li *ngFor="let category of categoriesDisplayed" class="px-2 px-md-3 li-category">
      <a *ngIf="category.categories && getCategoriesMenu(category.categories).length > 0 && !category.microFiche "
        class="cursor-pointer text-black text-decoration-none w-100 d-flex" (click)="onOpenSubMenu(category)">
        <img [src]="category.fullUrlPictureFull" [alt]="category.designation" [title]="category.designation"
          class="my-auto mr-2">
        <span class="my-auto">{{category.designation}} </span>
        <span class="ml-auto k-chevron-right f-size-24px my-auto"></span>
      </a>

      <a [routerLink]="category.linkCategoryNew" (click)="drawerMenu.close()"
        *ngIf="!category.categories || getCategoriesMenu(category.categories).length == 0 || category.microFiche"
        class="cursor-pointer text-black w-100 d-flex text-decoration-none">
        <img [src]="category.fullUrlPictureFull" [alt]="category.designation" [title]="category.designation"
          class="my-auto mr-2" >
        <!--<img src="assets/img/category/{{category.designation}}.svg" style="max-height:24px;" class="mr-2">-->
        <span class="text-center d-block my-auto">{{category.designation}} </span>
        <span class="ml-auto k-chevron-right f-size-24px my-auto"></span>
      </a>
      <hr class="my-0" />
    </li>
    <li class="pt-3 px-2 px-md-3 font-weight-bold" *ngIf="categoryParent">
      <a (click)="drawerMenu.close()" [routerLink]="categoryParent.linkCategoryNew"
        class="cursor-pointer text-black text-decoration-none w-100 d-flex">
        <span class="my-auto">{{'seeAll' | translate}}</span>
        <span class=" k-chevron-right f-size-24px ml-auto my-auto"></span>
      </a>
    </li>

  </ul>


  <div class="w-100 row mx-0 mb-0 mt-auto d-xl-none limit-sidenav bottom-sidenav">
    <button class="col-4 bg-grey border-0 d-flex justify-content-center h-100" [matMenuTriggerFor]="menuLang"><span
        class="k-world f-size-18px my-auto"></span>
        <!-- <span class="ml-3 font-weight-bold f-size-16px my-auto">{{selectedLanguageCode}}</span> -->
      </button>
    <mat-menu #menuLang="matMenu">
      <!-- <button mat-menu-item *ngFor="let lang of languages" (click)="navigate(lang.code)">
        {{lang.code.substring(0,2).toUpperCase()}}
      </button> -->

      <button mat-menu-item *ngFor="let theme of themes" (click)="onChangeTheme(theme.id)">
        <span [class.font-weight-bold]="selectedTheme && theme.id == selectedTheme.id">{{theme.name}}</span>
      </button>
    </mat-menu>
    <button class="col-8 bg-main-gradient border-0 d-flex justify-content-center" *ngIf="isAuthenticated"
      [routerLink]="['/',translateService.currentLang, 'manage', 'profile']"><span
        class="k-user f-size-18px my-auto"></span><span class="ml-3 font-weight-bold f-size-16px my-auto">{{'account' |
        translate}}</span></button>
    <button class="col-8 bg-main-gradient border-0 d-flex justify-content-center" *ngIf="!isAuthenticated"
      [routerLink]="['/', translateService.currentLang, 'auth']"><span class="k-user f-size-18px my-auto"></span><span
        class="ml-3 font-weight-bold f-size-16px my-auto">{{'account' | translate}}</span></button>
  </div>
</div>