export const environment = {
  production: true,
  FRONT_BASE_URL: 'https://preprod.smallmx.com',
  // API_BASE_URL: 'https://localhost:44341/',
  API_BASE_URL: 'https://api.smallmx.com/',
  API_VERSION: '',
  // API_VERSION: 'v2.0/',
  GOOGLE_ADS_ID: '',
  PAYPAL_CLIENT_ID: 'Aa_mvYVoRPs772D7uTyO0mYSf0PgfMnypSRneqMua2pYx5fgc2t4DVMC06J7BGDD1CYN2IJ-RyvNEVi5',
  RECAPTCHA_SITE_KEY: '6Lf04K4nAAAAAEVbrkk4dcSi4w657HUa8v5aWmT2',
  RECAPTCHA_SECURITY_KEY: '6Lf04K4nAAAAAKU9HHrIbrAiDrgnxx18zA4o6t_4',
  INSTAGRAM_TOKEN: 'IGQWROWXBiMGVWVDNnNDZARek4yQk1oOXVnVHZApLWdYV0dsRVA5UmRhSEt6Y2p3TVNESk1uZAW1lREZA1VHRUdVNGOXREQTNodUpTdmRMX1FwZAk1tYlZAwWk5TYjY4UW8tQzY4eTdsUHdab0VBdwZDZD',
  SYSTEMPAY_KEY:'87758997:testpublickey_Aef99S4LNJbP5Q5fI1E4Q8tz0puZ8gAbT40ma4Dx8Unhf',
  DOOFINDER: {
    URL: 'https://eu1-search.doofinder.com/5/',
    TOKEN: 'eu1-2c96befd7f19f09a451f0988a1092cab863838d0',
    HASH_ID: 'ae780fdbad932b931b8fc91ac8234550'
  },
  PRODUCT_FILTERS:{
    ATTRIBUT_ID:{
      HEIGHT: 15516,
      WEIGHT: 15517,
    }
  },
  CATEGORIES:{
    VEHICULE:2609
  },
  ATTRIBUTS: {
    COLOR:29,
    ENGINE_TYPE:15518,
  },
  ATTRIBUT_VALUE: {
    ELECTRIQUE:1331,
    THERMIQUE:1332,
  }
};
