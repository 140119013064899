<!--<div style="cursor:default; background-color: greenyellow" class="col-12 px-0 row mx-0">
  <div class="col-12 bloc-img px-0 ">-->
<!-- <img [src]="product.pictureSmallUrl" alt="" width="100%"> -->
<!-- <ng-image-slider [images]="productPictures"
[imageSize]="{width:'100%', height:'140px'}"
[manageImageRatio]="true"
id="slider-{{product.productId}}"
#nav></ng-image-slider> -->
<!-- BLOC IMAGE PRODUIT -->
<!--<ngu-carousel #myCarouselImagesItem [inputs]="carouselConfigProductImages" [dataSource]="carouselItems"
              class="w-100 h-100">-->
<!-- IMAGE PRODUIT -->
<!--<div *nguCarouselDef="let item;" class="item">
        <a [routerLink]="[ product.linkProduct ]" class="d-block h-100">
          <img [src]="item" alt="" class="img-carousel " style=" max-height:300px;" >
        </a>
      </div>

    </ngu-carousel>

  </div>
</div>-->
<div *ngIf="product.productPictures[0]" class="h-100 d-flex d-none overflow-hidden justify-content-center content-img">
  <img *ngIf="product.productPictures[0]"   [src]="product.productPictures[0].pictureUrlLarge" [alt]="product.productId" [title]="product.productId" id="slider-{{product.productId}}" />
</div>
<div *ngIf="!product.productPictures[0]" class="w-100 h-100 encar-info bg-grey-light my-auto px-3 px-md-4 py-4 border-main-gradient">
  <span class="font-italic font-weight-extrabold f-size-18px f-size-md-28px line-height-1">{{product.designationDynamic}}</span>
  <pre *ngIf="product.productDescription" class="f-size-12px f-size-md-14px mt-2 limit-encar">
    {{product.productDescription.description}}
  </pre>
</div>
